export default defineNuxtPlugin( {
  hooks: {
    async 'page:loading:end'() {
      const embed = useEmbed();
      embed.mount();
    },
    async 'page:start'() {
      const embed = useEmbed();
      embed.reset();
    }
  }
})
