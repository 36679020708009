export function generateRandomString(length = 8) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function toLowerDashed(param) {
  if(typeof param === "string") {
    return param.toLowerCase().replace(/ \/\s/g, '-').replace(/ /g, '-');
  }

  if(typeof param === "object") {
    Object.keys(param).forEach(key => {
      if(param[key]) {
        param[key] = param[key].toLowerCase().replace(/ \/\s/g, '-').replace(/ /g, '-');
      }
    });
    return param;
  }

  return null;
}

export function polishPlurals(singularNominativ, pluralNominativ, pluralGenitive, value) {
  value = Math.abs(value);
  if (value === 1) {
    return singularNominativ;
  } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
    return pluralNominativ;
  } else {
    return pluralGenitive;
  }
}
