export default defineNuxtRouteMiddleware(async (to, from) => {

    if (!to.path.startsWith('/kolekcje/')) {
        return
    }

    const requestUrl = useRequestURL();
    const backend = useBackend();

    console.log('🔌 MDLW:', 'catch-all', requestUrl.pathname);

    const slugParam = to.params.slug;
    const customUrlCandidate = Array.isArray(slugParam) ? slugParam.join('/') : slugParam || '';

    try {
        const response = await backend.getCollection(customUrlCandidate);
        if (response.data?.custom_url && response?.data.custom_url !== null) {
            return navigateTo("/" + response.data.custom_url, {redirectCode: 301, replace: true})
        }
      } catch (err) {
        console.error("❌ Backend error:", err);
      }
  })
