import { useEnvironment } from "~/composables/Environment";

export const useAnalytics = () => {

  const {isDevelopment} = useEnvironment();
  const console = useConsole();

  const filterOptionsEventNames = {
    body: "filter_nadwozie",
    brand: "filter_marka",
    model: "filter_model",
    productionYear: "filter_rok_produkcji",
    fuel: "filter_paliwo",
    gearbox: "filter_skrzynia",
    drive: "filter_naped",
    seats: "filter_miejsca",
    powerMin: "filter_moc_od",
    powerMax: "filter_moc_do",
    state: "filter_stan"
  }
  const addToFavorites = (brand, model, purpose, body) => {
    analyticsLayer({
      event: 'add_to_favorites',
      brand: brand,
      model: model,
      purpose: purpose,
      body: body
    })
  };

  const initialFeeValueChange = (value) => {
    analyticsLayer({
      event: 'calculator_oplata_wstepna',
      type: value
    })
  }

  const repurchaseValueChange = (value) => {
    analyticsLayer({
      event: 'calculator_wartosc_wykupu',
      type: value
    })
  }

  const clientTypeChange = (value) => {
    analyticsLayer({
      event: 'calculator_klient',
      type: value
    })
  }

  const durationChange = (value) => {
    analyticsLayer({
      event: 'calculator_okres',
      duration: value
    })
  }

  const bodyTypeChosen = (body) => {
    analyticsLayer({
      event: 'configurator_nadwozie',
      body: body
    })
  }

  const brandChosen = (brand) => {
    analyticsLayer({
      event: 'configurator_brand',
      brand: brand
    })
  }

  const purposeChosen = (purpose) => {
    analyticsLayer({
      event: 'configurator_purpose',
      purpose: purpose
    })
  }

  const clickOfferType = (type) => {
    analyticsLayer({
      event: 'offer_type',
      type: type
    })
  }

  const generateLeadBox = (phone = null, transactionId = null) => {
    analyticsLayer({
      event: 'generate_lead_box',
      phone: phone,
      transactionId: transactionId,
    })
  }

  const generateLeadCars = (brand, model, purpose, body, phone = null, mail = null, transactionId = null) => {
    analyticsLayer({
      event: 'generate_lead_cars',
      brand: brand,
      model: model,
      purpose: purpose,
      body: body,
      phone: phone,
      email: mail,
      transactionId: transactionId,
    })
  }

  const generateLeadDetail = (brand, model, purpose, body, phone = null, mail = null, transactionId = null) => {
    analyticsLayer({
      event: 'generate_lead_detail',
      brand: brand,
      model: model,
      purpose: purpose,
      body: body,
      phone: phone,
      email: mail,
      transactionId: transactionId,
    })
  }

  const generateLeadDigitalOrderWithStatus = (transactionId, email, phone, decyzja, brand, model, trans_rata, trans_cena) => {
    analyticsLayer({
      event: 'trans_form_done',
      transactionId: transactionId,
      email: email,
      phone: phone,
      decyzja: decyzja,
      brand: brand,
      model: model,
      trans_rata: trans_rata,
      trans_cena: trans_cena,
    })
  }

  const analyticsLayer = (data) => {
    console.log('🍰 dataLayer.push', data.event || "unknown", data)
    if (isDevelopment || process.server || typeof dataLayer === "undefined") {
      console.log('... skipping (dev)')
      return;
    }
    dataLayer.push(data)
  };

  const sendEvent = (eventName, email = null, phone = null, transactionId = null) => {
    analyticsLayer({'event': eventName, 'email': email, 'phone': phone, 'transactionId': transactionId});
  }

  return {
    generateLeadBox,
    generateLeadDetail,
    generateLeadCars,
    clickOfferType,
    purposeChosen,
    brandChosen,
    bodyTypeChosen,
    repurchaseValueChange,
    initialFeeValueChange,
    clientTypeChange,
    durationChange,
    addToFavorites,
    sendEvent,
    analyticsLayer,
    generateLeadDigitalOrderWithStatus,
    filterOptionsEventNames
  }
}
