export const useBackendStore = () => {
  const route = useRoute();
  const brands = useState('brandsStore', () => []);
  const bodies = useState('bodiesStore', () => []);
  const carCount = useState('carCountStore', () => 0);
  const gearboxes = useState('gearboxesStore', () => []);
  const drives = useState('drivesStore', () => []);
  const purposes = useState('purposesStore', () => []);
  const setup = useState('setupStore', () => []);
  const isEmbed = useState('isEmbedStore', () => false)

  function setCarCount(count) {
    carCount.value = count
  }

  function setBrands(newBrands) {
    brands.value = newBrands
  }

  function setBodies(newBodies) {
    bodies.value = newBodies
  }

  function setPurposes(newPurposes) {
    purposes.value = newPurposes
  }

  function setSetup(newSetup) {
    setup.value = newSetup;
  }

  function setGearboxes(newGearboxes) {
    gearboxes.value = newGearboxes
  }

  function setDrives(newDrives) {
    drives.value = newDrives
  }

  function setIsEmbed(value) {
    isEmbed.value = value;
  }

  return {
    brands,
    setBrands,
    bodies,
    setBodies,
    purposes,
    setPurposes,
    setup,
    setSetup,
    drives,
    setDrives,
    gearboxes,
    setGearboxes,
    setCarCount,
    carCount,
    isEmbed,
    setIsEmbed
  };
}
