import { createGtm } from "@gtm-support/vue-gtm";
import gtms from "~/configs/gtms";


export default defineNuxtPlugin((nuxtApp) => {
  const { isDevelopment} = useEnvironment();
  var id;
  const request = useRequestURL();
    const domain = request.href;
    if (domain.includes('carkulacja')) {
      id = gtms.carculacja;
    } else if (domain.includes('vehistools')) {
      id = gtms.vehistools;
    } else if (domain.includes('embed.vehis.pl')) {
      id = gtms.embed;
    } else  {
      id = gtms.default;
    }

    if(!domain.includes('?pdf=1')) {
      console.log('dom', domain)
      nuxtApp.vueApp.use(createGtm({
        id: id,
        defer: false,
        compatibility: false,
        enabled: !isDevelopment,
        debug: isDevelopment,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false
      }));
    }
})
