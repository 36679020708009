export const useEmbed = () => {
  const backendStore = useBackendStore();
  const router = useRouter();

  let timeout;
  const updateHeight = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const height = document.getElementById("__nuxt").getBoundingClientRect().height; //Math.max(document.documentElement.scrollHeight, window.innerHeight);
      window.parent.postMessage(height, "*");
      window.parent.postMessage(window.location.pathname, '*');
    }, 200);
  }

  const mount = () => {
    if (!backendStore.isEmbed) return
    updateHeight();
    if (window.isVEmbed) return;
    window.addEventListener("resize", updateHeight);
    window.isVEmbed = true;
    router.afterEach(() => {
      nextTick(() => updateHeight());
    })
  }

  const reset = () => {
    window.parent.postMessage(1, '*');
  }

  return {
    mount,
    reset,
    updateHeight
  }
}
